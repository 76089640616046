import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import SwiperCore, { EffectFade, Pagination } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'
import 'swiper/components/pagination/pagination.scss'

import ButtonsSlideCases from '../ButtonsSlideCases'
import LetsTalk from '../LetsTalk'
import ArrowIcon from 'src/components/SvgSprite/ArrowIcon'
import Picture from '../Picture'
import SvgSprite from 'src/components/SvgSprite'

import styles from './styles.module.scss'
import {
    caseLinguamillApp,
    caseCalatonStudioWeb,
    caseCalatonStudioBranding,
    caseCalatonSystemsWeb,
    caseCalatonSystemsBranding,
} from './cases.js'

const buttons = [
    {
        title: 'All cases',
        cases: [
            caseLinguamillApp,
            caseCalatonStudioWeb,
            caseCalatonStudioBranding,
            caseCalatonSystemsWeb,
            caseCalatonSystemsBranding,
        ],
    },
    {
        title: 'Brand Identity',
        cases: [caseCalatonSystemsBranding, caseCalatonStudioBranding],
    },
    {
        title: 'Web & UI/UX Design',
        cases: [caseCalatonSystemsWeb, caseCalatonStudioWeb],
    },
    {
        title: 'Mobile & Desktop App',
        cases: [caseLinguamillApp],
    },
]

const CasesPage = () => {
    const filter = typeof window !== 'undefined' && sessionStorage.getItem('filterCases')
    const [activeSlide, setActiveSlide] = useState(initActiveIndex(filter))
    const [prevDisable, setPrevDisable] = useState(true)
    const [nextDisable, setNextDisable] = useState(false)

    SwiperCore.use([EffectFade, Pagination])

    const sliderCases = useRef(null)

    useEffect(() => {
        sliderCases.current.swiper.slideTo(activeSlide)
        sessionStorage.removeItem('filterCases')
    })

    function initActiveIndex(filter) {
        switch (filter) {
            case 'branding':
                return 1
            case 'webDesign':
                return 2
            case 'mobileApp':
                return 3
            default:
                return 0
        }
    }

    const changeSlide = index => {
        setActiveSlide(index)
    }

    const switchToThePrevSlide = () => {
        if (!prevDisable) setActiveSlide(activeSlide - 1)
    }

    const switchToTheNextSlide = () => {
        if (!nextDisable) setActiveSlide(activeSlide + 1)
    }

    const setDisable = swiper => {
        setPrevDisable(swiper.isBeginning)
        setNextDisable(swiper.isEnd)
    }

    const getImgCase = item => {
        return (
            <>
                {(item.img && <img src={item.img} srcSet={`${item.retina} 2x`} alt={item.alt} />) ||
                    (item.picture && (
                        <div className={styles.pictureContainer}>
                            <Link to={item.link}>
                                <Picture pic={item.picture} />
                            </Link>
                        </div>
                    ))}
            </>
        )
    }

    const getSlideWithCases = cases => {
        const classNameBtnViewProject = classNames(styles.btnViewProject, 'btn')
        const classNameMobileCaseTitle = classNames(styles.caseTitle, styles.caseTitleMobile)
        const classNameDesktopCaseTitle = classNames(styles.caseTitle, styles.caseTitleDesktop)

        return cases.map((item, i) => (
            <div key={i} className={styles.case} data-href={item.link}>
                <h2>
                    <Link to={item.link}>
                        <span
                            className={classNameMobileCaseTitle}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                        ></span>
                    </Link>
                </h2>
                {getImgCase(item)}
                <div>
                    <h2>
                        <Link to={item.link}>
                            <span
                                className={classNameDesktopCaseTitle}
                                dangerouslySetInnerHTML={{ __html: item.title }}
                            ></span>
                        </Link>
                    </h2>
                    <p className={styles.caseDescription}>{item.description}</p>
                    <Link className={classNameBtnViewProject} to={item.link}>
                        View project
                    </Link>
                </div>
                <SvgSprite name="circleIcon" className={styles.circleIcon} />
            </div>
        ))
    }

    const arrowSecondary = {
        usual: 'arrowSecondary',
        hover: 'arrowSecondaryHover',
        disabled: 'arrowSecondaryDisabled',
    }

    const casesPageClassName = classNames(styles.casesPage, 'container')
    const nextBtnClass = classNames('next', styles.next, styles.arrow)
    const prevBtnClass = classNames('prev', styles.prev, styles.arrow)
    const pagination = classNames('swiper-pagination swiper-pagination-bullets ', styles.pagination)

    const parameters = {
        autoHeight: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
    }

    return (
        <>
            <section className={casesPageClassName}>
                <h1 className={styles.title}>Cases</h1>
                <ButtonsSlideCases
                    buttons={buttons}
                    activeIndex={activeSlide}
                    onClick={changeSlide}
                />
                <Swiper
                    ref={sliderCases}
                    className={styles.gridSlider}
                    {...parameters}
                    onProgress={setDisable}
                >
                    {buttons.map(({ title, cases }) => (
                        <SwiperSlide key={title} className={styles.grid}>
                            {getSlideWithCases(cases)}
                        </SwiperSlide>
                    ))}
                    <div className={styles.control}>
                        <div className={styles.navigation}>
                            <ArrowIcon
                                className={prevBtnClass}
                                disabled={prevDisable}
                                arrow={arrowSecondary}
                                onClick={switchToThePrevSlide}
                            />
                            <div className={pagination}></div>
                            <ArrowIcon
                                className={nextBtnClass}
                                disabled={nextDisable}
                                arrow={arrowSecondary}
                                onClick={switchToTheNextSlide}
                            />
                        </div>
                    </div>
                </Swiper>
                <LetsTalk className={styles.letsTalk} />
            </section>
        </>
    )
}

export default CasesPage
