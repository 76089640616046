import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

const ButtonsSlideCases = ({ buttons, className, activeIndex, onClick }) => {
    const btnActiveClassName = (className, i) =>
        classNames(className, { [styles.active]: activeIndex === i })

    const buttonsItemOnClickHandler = event => {
        const target = event.target
        const index = target.getAttribute('data-index')
        onClick(+index)
    }

    const buttonsClassName = classNames(styles.buttons, className)

    return (
        <div className={buttonsClassName}>
            {buttons.map(({ title }, i) => (
                <button
                    className={btnActiveClassName(styles.buttonsItem, i)}
                    key={title}
                    data-index={i}
                    onClick={buttonsItemOnClickHandler}
                >
                    {title}
                </button>
            ))}
        </div>
    )
}

ButtonsSlideCases.propTypes = {
    buttons: PT.array.isRequired,
    onClick: PT.func,
    activeIndex: PT.number,
    className: PT.string,
}

export default ButtonsSlideCases
