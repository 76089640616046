import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Header from 'src/components/Header'
import CasesPage from 'src/components/CasesPage'
import Footer from 'src/components/Footer'
import Meta from 'src/components/Meta'
import ogLogoCompany from 'src/images/optimized/jpg/cases/og-logo_company.jpg'

const metaData = {
    title: 'Calaton Studio - Cases',
    description: 'Calaton Studio we have many cases with completed works',
    url: 'https://www.calaton.studio/cases',
    img: `https://www.calaton.studio${ogLogoCompany}`,
    keywords: 'Calaton Studio, cases',
}

const Cases = () => (
    <>
        <Meta content={metaData} />
        <BaseContainer>
            <Header />
            <CasesPage />
            <Footer />
        </BaseContainer>
    </>
)

export default Cases
